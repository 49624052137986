import React from "react";
import { connect } from "react-redux";
import { Avatar } from "@material-ui/core";

// core components

class Images extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      img: null,
      imgLoaded: false,
    };
    this.onTokenLoad = this.onTokenLoad.bind(this);
  }

  onTokenLoad(token, src) {
    if (!token || typeof token == "undefined") {
      return null;
    }

    const jwt = token.t;

    if (!jwt || typeof jwt == "undefined" || jwt == "undefined") {
      return null;
    }
    if (!src || typeof src == "undefined") {
      return null;
    }

    return src + "?token=" + jwt;
  }

  handleImageLoaded = () => {
    this.setState({ imgLoaded: true });
  };

  render() {
    const {
      src,
      alt,
      class_name,
      handleClick,
      token_file,
      avatar,
    } = this.props;
    const displaySrc = this.onTokenLoad(token_file, src);

    const imgStyles = this.state.imgLoaded ? {} : { visibility: "hidden" };

    const { img } = this.state;
    if (avatar) {
      return (
        <Avatar
          src={displaySrc}
          onLoad={this.handleImageLoaded}
          className={class_name}
          style={imgStyles}
        ></Avatar>
      );
    } else {
      return (
        <img
          src={displaySrc}
          onLoad={this.handleImageLoaded}
          alt={alt}
          className={class_name}
          onClick={handleClick}
          style={imgStyles}
        />
      );
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  token_file: state.authReducer.token_file,
  isReady: state.authReducer.ready,
});

export default connect(mapStateToProps)(Images);
